import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../../../Layout";
import Seo from "../../../components/Seo/Seo";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import PageBanner from "../../../components/PageBanner/PageBanner";
import ShapeBackground from "../../../Molecules/ShapeBackground/ShapeBackground";
import TextVisuelTwoCol from "../../../components/ContentType/TextVisuelTwoCol/TextVisuelTwoCol";
import TitlePage from "../../../Molecules/TitlePage/TitlePage";
import TitleSection from "../../../Molecules/TitleSection/TitleSection";
import ScrollPageNav from '../../../components/ScrollPageNav/ScrollPageNav'

import "../engagements.scss";
var classNames = require("classnames");

const PrincipauxEnjeux = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "slider-image.png" }) {
        childImageSharp {
          mobile: fixed(width: 456, height: 456) {
            ...GatsbyImageSharpFixed
          }
          desktop: fluid {
            ...GatsbyImageSharpFluid
          }
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const leftRightBlocks = [
    {
      text: "<p>L’ambition de Colas est le zéro accident. En ce sens, le Groupe assure la santé-sécurité de tous ses collaborateurs en respectant les dispositions légales en vigueur dans les pays où nous opérons. Colas œuvre également à travers des programmes de sensibilisation ad hoc et en mettant à disposition de ses collaborateurs des équipements adaptés aux besoin de chacun.</p><p>De ce fait, en septembre 2020, les « Règles Groupe » (règles de vie et règles qui sauvent) ont été lancées par Frédéric Gardès, PDG de Colas. Elles sont depuis progressivement déployées avec des supports appropriés (films, affiches, méthodes, …) pour en faciliter une compréhension effective et ainsi nous permettre d’atteindre, ensemble, une culture sécurité homogène.</p>",
      title:
        "Développer la culture santé et sécurité pour l’ensemble des parties prenantes",
      visuel: data.file.childImageSharp
    },
    {
      text: "<p>La protection des collaborateurs et la préservation de l’activité du Groupe contre les actes de malveillance est une priorité. Les enjeux se situent sur la prévention et l’anticipation des principaux risques et sur l’accompagnement des situations de crise.</p><p><strong>Les outils à dispositions de la sûreté et la gestion de crise sont les suivants :</strong></p><ul><li><strong>Le Golden Book :</strong> Fiches réflexes très synthétiques recensant les actions essentielles à entreprendre en situation d’urgence.</li><li><strong>Le Plan de Continuité d’Activité (PCA) :</strong> il couvre l’ensemble du personnel, des ressources, des services et des actions clés nécessaires pour gérer le processus de gestion de la continuité des activités.</li><li><strong>Le Manuel de gestion de crise :</strong> il présente les différentes étapes à suivre en cas de crise</li></ul>",
      title: "Déployer une démarche sûreté et gestion de crise",
      visuel: data.file.childImageSharp
    },
    {
      text: "<p>La protection des collaborateurs et la préservation de l’activité du Groupe contre les actes de malveillance est une priorité. Les enjeux se situent sur la prévention et l’anticipation des principaux risques et sur l’accompagnement des situations de crise.</p><p><strong>Les outils à dispositions de la sûreté et la gestion de crise sont les suivants :</strong></p><ul><li><strong>Le Golden Book :</strong> Fiches réflexes très synthétiques recensant les actions essentielles à entreprendre en situation d’urgence.</li><li><strong>Le Plan de Continuité d’Activité (PCA) :</strong> il couvre l’ensemble du personnel, des ressources, des services et des actions clés nécessaires pour gérer le processus de gestion de la continuité des activités.</li><li><strong>Le Manuel de gestion de crise :</strong> il présente les différentes étapes à suivre en cas de crise</li></ul>",
      title: "Déployer une démarche sûreté et gestion de crise",
      visuel: data.file.childImageSharp
    },
  ];


  return (
    <Layout>
      <Seo title="Nos principaux enjeux" description="Nos principaux enjeux" />
      <div
        className={classNames(
          "page_template",
          "principaux_enjeux"
        )}
      >
        <ScrollPageNav
          data={[
            {
              title: 'Enjeux du développement durable', //"hide" = not visible on scrollnav
              section: 'block_0_section'
            },
            {
              title: 'Impacts et risques',
              section: 'block_1_section'
            },
            {
              title: 'Project ACT',
              section: 'block_2_section'
            }
          ]}
        />
        <PageBanner visuel={data.file.childImageSharp.fluid} scroll={true}>
          <div className="wrapper_page">
            <Breadcrumb
              page_banner={true}
              data={{
                grandParentPage: { title: "Acceuil", url: "/" },
                parentPage: { title: "Engagements", url: "/engagements" },
                currentPage: {
                  title: "Nos principaux enjeux",
                  url: "/principaux-enjeux",
                },
              }}
            />
            <TitlePage color="color_white" title="Nos principaux enjeux" />
          </div>
        </PageBanner>

        <section className={classNames("section_content", "block_0_section")}>
          <div className="wrapper_page_xs">
            <TitleSection
              title="Enjeux du développement durable"
            />
            {leftRightBlocks.map((block, index) => (
              <TextVisuelTwoCol
                key={index}
                title={block.title}
                extremeRight={(index % 2 == 0) ? true : false}
                extremeLeft={(index % 2 == 0) ? false : true}
                orderInverse={(index % 2 == 0) ? true : false}
                visuel={block.visuel}
                extactHalf={(index % 2 == 0) ? true : false}
                alignLeft={(index % 2 == 0) ? true : false}
                alignTop={true}
                text={block.text}
              />
            ))}
          </div>
        </section>
        <section className={classNames('section_content', 'block_1_section')}>
          <ShapeBackground
            top="left"
            color="catskill_white"
            bottomInfinite={true}
          >
            <div className="wrapper_page_xs">
              <TitleSection title="Impacts et risques" type="arrow" />
              {leftRightBlocks.map((block, index) => (
                <TextVisuelTwoCol
                  key={index}
                  title={block.title}
                  extremeRight={(index % 2 == 0) ? false : true}
                  extremeLeft={(index % 2 == 0) ? true : false}
                  orderInverse={(index % 2 == 0) ? false : true}
                  visuel={block.visuel}
                  extactHalf={(index % 2 == 0) ? false : true}
                  alignLeft={(index % 2 == 0) ? false : true}
                  alignTop={true}
                  text={block.text}
                />
              ))}
            </div>
          </ShapeBackground>
        </section>
        <section className={classNames('section_content', 'block_2_section')}>
          <ShapeBackground
            top="left"
            color="dark_bleu z-index-top"
            bottomInfinite={true}
          >
            <div className="wrapper_page_xs">
              <TitleSection title="Project ACT" type="arrow" />
              {leftRightBlocks.map((block, index) => (
                <TextVisuelTwoCol
                  key={index}
                  title={block.title}
                  extremeRight={(index % 2 == 0) ? false : true}
                  extremeLeft={(index % 2 == 0) ? true : false}
                  orderInverse={(index % 2 == 0) ? false : true}
                  visuel={block.visuel}
                  extactHalf={(index % 2 == 0) ? false : true}
                  alignLeft={(index % 2 == 0) ? false : true}
                  alignTop={true}
                  text={block.text}
                  colorTitle="color_white"
                  colorDescription="color_white"
                />
              ))}
            </div>
          </ShapeBackground>
        </section>
      </div>
    </Layout>
  );
};

export default PrincipauxEnjeux;
